exports.components = {
  "component---node-modules-dvcorg-gatsby-theme-iterative-src-templates-doc-tsx": () => import("./../../../node_modules/@dvcorg/gatsby-theme-iterative/src/templates/doc.tsx" /* webpackChunkName: "component---node-modules-dvcorg-gatsby-theme-iterative-src-templates-doc-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-doc-user-guide-glossary-tsx": () => import("./../../../src/pages/doc/user-guide/glossary.tsx" /* webpackChunkName: "component---src-pages-doc-user-guide-glossary-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subscriber-thank-you-tsx": () => import("./../../../src/pages/subscriber-thank-you.tsx" /* webpackChunkName: "component---src-pages-subscriber-thank-you-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

